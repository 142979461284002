import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function PolitykaPrywatnosci() {
  const seo = {
    title: "Polityka prywatności - \"Medicus-Dukla\" & Poradnia Medycyny Rodzinnej",
    description: "Administratorem danych osobowych jest Medicus-Dukla Sp. z o.o. z siedzibą: Dukla, ul. Trakt Węgierski 16, tel:. 13 43 30 328. Kontakt z Inspektorem Ochrony Danych – iod@netmol.pl",
    url: "https://medicusdukla.pl/polityka-prywatnosci"
  }

  return (
    <Layout pageClass={"sample-page"} bg={null}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section data-g="container" className="o-privacy-policy">
        <h1 className="a-heading-small">Polityka prywatności</h1>
        <ol>
          <li>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach
            cookies.
          </li>
          <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które
            przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
            internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
            przechowywania ich na urządzeniu końcowym oraz unikalny numer.
          </li>
          <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do
            nich dostęp jest operator Serwisu Medicus-Dukla Sp. z O. O z siedzibą pod adresem Dukla,
            ul. Trakt Węgierski 16, tel. 13 43 30 328.
          </li>
          <li>Pliki cookies wykorzystywane są w celu:
            <ol>
              <li>Dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji
                korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika
                Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;
              </li>
              <li>Tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron
                internetowych, co umożliwia ulepszanie ich struktury i zawartości;
              </li>
              <li> Utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej
                podstronie Serwisu ponownie wpisywać loginu i hasła;
              </li>
            </ol>
          </li>
          <li>W ramach Serwisu stosowane są następujące rodzaje plików cookies:
            <ol>
              <li>„Niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np.
                uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;
              </li>
              <li> Pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w
                zakresie uwierzytelniania w ramach Serwisu;
              </li>
              <li>„Wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron
                internetowych Serwisu;
              </li>
              <li>„Funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i
                personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi
                Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;
              </li>
              <li>„Reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej
                dostosowanych do ich zainteresowań.
              </li>
            </ol>
          </li>

          <li>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa)
            domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu
            mogą
            dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
            szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki
            internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe
            informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
            (przeglądarki internetowej).
          </li>
          <li>Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
            funkcjonalności dostępne na stronach internetowych Serwisu.
          </li>
          <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również
            przez
            współpracujących z operatorem Serwisu reklamodawców oraz partnerów.
          </li>
          <li>Obowiązek informacyjny Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27
            kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) informuję, iż:
            <ol>
              <li>Administratorem Pani/Pana danych osobowych jest Medicus-Dukla Sp. z O. O. z siedzibą: Dukla, ul. Trakt
                Węgierski 16, tel. 13 43 30 328.
              </li>
              <li>Kontakt z Inspektorem Ochrony Danych - iod@netmol.pl</li>
              <li>Pani/Pana dane są przetwarzane w celach administrowania serwisem, statystyki odwiedzin serwisu,
                zarządzania treścią serwisu, utrzymaniem kontaktu z Użytkownikami na podstawie art. 6 ust. 1 lit. f RODO
                oraz w innych celach określonych każdorazowo w przekazywanych formularzach zgody na podstawie art. 6
                ust.
                1 lit. a RODO.
              </li>
              <li>Odbiorcami Pani/Pana danych osobowych będą wyłącznie podmioty uprawnione do uzyskania danych osobowych
                na podstawie przepisów prawa oraz podmioty uczestniczące w realizacji usług.
              </li>
              <li>Pani/Pana dane osobowe przechowywane będą przez okres 2 lat.</li>
              <li>Posiada Pani/Pan prawo do żądania od administratora dostępu do danych osobowych, prawo do ich
                sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do
                wniesienia
                sprzeciwu wobec przetwarzania oraz prawo do cofnięcia zgody na przetwarzanie w dowolnym momencie.
              </li>
              <li>Posiada Pani/Pan prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</li>
              <li>Pani/Pana dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą podlegały
                profilowaniu.
              </li>
            </ol>
          </li>
        </ol>
        <br/>
        <h2 className={"a-heading-small"}>Załączniki</h2>
        <br/>
        {[
          { title: "Klauzula kandydat na pracownika", file: "klauzula kandydat na pracownika" },
          { title: "Klauzula kontrahent", file: "klauzula kontrahent" },
          { title: "Klauzula petent", file: "klauzula petent" },
          { title: "Klauzula pacjent", file: "klauzula pacjent" },
          { title: "Klauzula pracownik", file: "klauzula pracownik" }
        ].map(({ title, file }, key) => (
          <div className="document" style={{fontWeight: 600, maxWidth: '400px', display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
            <div style={{color: '#1e1e1e'}}>{title}</div>
            <div>
                <a href={`/uploads/${file}.pdf`} target="_blank" rel="noopener noreferrer">pobierz pdf</a>
              </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}
